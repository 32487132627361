import request from '../utils/request'
import baseUrl from './baseUrl'
export const typeList = data =>
	request({
		url: baseUrl + '/prodType/selectForBack',
		method: 'POST',
		data
	})
export const typeEdit = data =>
	request({
		url: baseUrl + '/prodType/addOrUpdate',
		method: 'POST',
		data
	})
export const typeDelete = data =>
	request({
		url: baseUrl + '/prodType/delete',
		method: 'POST',
		data
	})

export const expressList = data =>
	request({
		url: baseUrl + '/express/selectForBack',
		method: 'POST',
		data
	})
export const expressEdit = data =>
	request({
		url: baseUrl + '/express/addOrUpdate',
		method: 'POST',
		data
	})
export const expressDelete = data =>
	request({
		url: baseUrl + '/express/delete',
		method: 'POST',
		data
	})

export const productList = data =>
	request({
		url: baseUrl + '/prod/selectForBack',
		method: 'POST',
		data
	})
// 添加
export const productAdd = data =>
	request({
		url: baseUrl + '/prod/add',
		method: 'POST',
		data
	})
// 修改排序
export const productEditSort = data =>
	request({
		url: baseUrl + '/prod/updateSort',
		method: 'POST',
		data
	})
// 修改
export const productEdit = data =>
	request({
		url: baseUrl + '/prod/update',
		method: 'POST',
		data
	})
// 批量上架、下架、删除
export const productModify = data =>
	request({
		url: baseUrl + '/prod/upDownOrDelete',
		method: 'POST',
		data
	})
// 批量设置/取消热门
export const prodChangeHot = data =>
	request({
		url: baseUrl + '/prod/updateHot',
		method: 'POST',
		data
	})
// 查询详情
export const productDetail = data =>
	request({
		url: baseUrl + '/prod/selectForId',
		method: 'POST',
		data
	})
export const shopList = data =>
	request({
		url: baseUrl + '/store/selectForBack',
		method: 'POST',
		data
	})
// 添加/修改
export const shopEdit = data =>
	request({
		url: baseUrl + '/store/addOrUpdate',
		method: 'POST',
		data
	})
// 批量禁用/启用
export const shopModify = data =>
	request({
		url: baseUrl + '/store/startOrEnd',
		method: 'POST',
		data
	})
// 查询详情
export const shopDetail = data =>
	request({
		url: baseUrl + '/express/delete',
		method: 'POST',
		data
	})

export const couponList = data =>
	request({
		url: baseUrl + '/store/coupon/selectList',
		method: 'POST',
		data
	})
// 添加
export const couponAdd = data =>
	request({
		url: baseUrl + '/store/coupon/addCoupon',
		method: 'POST',
		data
	})
// 修改/删除/上下架
export const couponModify = data =>
	request({
		url: baseUrl + '/store/coupon/updateCoupon',
		method: 'POST',
		data
	})

export const storeOrderList = data =>
	request({
		url: baseUrl + '/storeOrder/selectForBack',
		method: 'POST',
		data
	})
// 订单详情
export const orderDetailApi = data =>
	request({
		url: baseUrl + '/storeOrder/selectByInfo',
		method: 'POST',
		data
	})
// 售后订单列表
export const orderService = data =>
	request({
		url: baseUrl + '/storeOrder/selectExitForBack',
		method: 'POST',
		data
	})
// 订单发货
export const orderToSend = data =>
	request({
		url: baseUrl + '/storeOrder/orderSend',
		method: 'POST',
		data
	})
// 查询所有物流
export const expressAll = data =>
	request({
		url: baseUrl + '/express/selectForAll',
		method: 'POST',
		data
	})
// 退款详情
export const returnDetail = data =>
	request({
		url: baseUrl + '/storeOrder/selectExitInfo',
		method: 'POST',
		data
	})
// 同意退款申请
export const returnApply = data =>
	request({
		url: baseUrl + '/storeOrder/confirmExit',
		method: 'POST',
		data
	})
// 拒绝退款申请
export const returnApplyCancel = data =>
	request({
		url: baseUrl + '/storeOrder/refuseExit',
		method: 'POST',
		data
	})
// 确认退款
export const returnConfirm = data =>
	request({
		url: baseUrl + '/storeOrder/confirmExitMoney',
		method: 'POST',
		data
	})
// 物流详情
export const getExpressDetail = data =>
	request({
		url: baseUrl + '/storeOrder/selectExpInfo',
		method: 'POST',
		data
	})
// 万小店商品列表
export const selectMallProfitList = data =>
	request({
		url: baseUrl + '/prod/selectForProfit',
		method: 'POST',
		data
	})


export const wareList = (data) => request({
    url: baseUrl + '/warehouse/selectForBack',
    method: 'POST',
    data
  })

export const addWare = (data) => request({
    url: baseUrl + '/warehouse/add',
    method: 'POST',
    data
  })
export const delWare = (data) => request({
    url: baseUrl + '/warehouse/deleteById',
    method: 'POST',
    data
  })
export const wareDetails = (data) => request({
    url: baseUrl + '/warehouse/selectForId',
    method: 'POST',
    data
  })
  export const getAfterList=(data)=>request({//商城订单-售后列表-查询
    url:baseUrl+'/prodBack/selectForBack',
    method:'post',
    data
})
export const getAfterShopOrderDetails=(data)=>request({//商城订单-售后列表-查看详情
    url:baseUrl+'/prodBack/selectForId',
    method:'post',
    data
})
export const examine=(data)=>request({//商城订单-售后列表-审核
    url:baseUrl+'/prodBack/modifyApply',
    method:'post',
    data
})
export const setFee=(data)=>request({//商城订单-售后列表-修改退款金额
    url:baseUrl+'/prodBack/modifyFee',
    method:'post',
    data
})
export const getAllWar=(data)=>request({//商城订单-售后列表-查询全部仓库
    url:baseUrl+'/warehouse/selectAll',
    method:'post',
    data
})
export const back=(data)=>request({//商城订单-售后列表-回退仓库
    url:baseUrl+'/prodBack/modifyRefund',
    method:'post',
    data
})
export const enterGoods=(data)=>request({//商城订单-售后列表-确认收货
    url:baseUrl+'/prodBack/modifySureOrder',
    method:'post',
    data
})
export const enterPay=(data)=>request({//商城订单-售后列表-确认打款
    url:baseUrl+'/prodBack/modifyRefundFee',
    method:'post',
    data
})
export const enterRejection=(data)=>request({//商城订单-售后列表-强制拒绝
    url:baseUrl+'/prodBack/modifyNoPass',
    method:'post',
    data
})
  // 导出订单
  export const inPort = (data) => request({
	url: baseUrl + '/storeOrder/selectExcel',
	method: 'POST',
	data,
	responseType:'blob',

  })
  // 订单详情收货
export const enterSh = (data) => request({
	url: baseUrl + '/storeOrder/modifySH',
	method: 'POST',
	data
	})
  // 小程序码
export const createEr = (data) => request({
	url: baseUrl + '/prod/shareErCode',
	method: 'POST',
	data
	})
  // 修改备注
export const remarks = (data) => request({
	url: baseUrl + '/storeOrder/addMasterRemark',
	method: 'POST',
	data
	})