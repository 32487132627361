var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"travelCateList"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("商品列表")])],1),_c('div',{staticClass:"search"},[_c('span',[_vm._v("商品名称:")]),_c('el-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.list.prodName),callback:function ($$v) {_vm.$set(_vm.list, "prodName", $$v)},expression:"list.prodName"}}),_c('span',[_vm._v("商品状态:")]),_c('el-select',{staticStyle:{"width":"200px","margin":"0 24px 0 10px"},attrs:{"clearable":""},model:{value:(_vm.list.status),callback:function ($$v) {_vm.$set(_vm.list, "status", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"list.status"}},[_c('el-option',{key:-1,attrs:{"label":"全部","value":undefined}}),_c('el-option',{key:1,attrs:{"label":"上架","value":1}}),_c('el-option',{key:2,attrs:{"label":"下架","value":2}}),_c('el-option',{key:3,attrs:{"label":"删除","value":3}})],1),_c('span',[_vm._v("支付方式:")]),_c('el-select',{staticStyle:{"width":"200px","margin":"0 24px 0 10px"},attrs:{"clearable":""},model:{value:(_vm.list.payWay),callback:function ($$v) {_vm.$set(_vm.list, "payWay", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"list.payWay"}},[_c('el-option',{key:-1,attrs:{"label":"全部","value":undefined}}),_c('el-option',{key:3,attrs:{"label":"积分+现金","value":3}}),_c('el-option',{key:1,attrs:{"label":"积分","value":1}}),_c('el-option',{key:2,attrs:{"label":"现金","value":2}})],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList('reset')}}},[_vm._v("查找")]),(
          _vm.$store.state.powerList.indexOf('selectMall:product:list:add')!==-1
        )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toEdit()}}},[_vm._v(" 添加 ")]):_vm._e()],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodSort","label":"排序","width":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.prodSortVisible)?_c('el-input-number',{directives:[{name:"focus",rawName:"v-focus",value:({
              cls:'el-input-number',
              tag:'input',
              foc:row.prodSort,
            }),expression:"{\n              cls:'el-input-number',\n              tag:'input',\n              foc:row.prodSort,\n            }"}],ref:"saveTagInput",attrs:{"size":"mini","step":1,"min":0,"step-strictly":true},on:{"blur":function($event){return _vm.handleInputConfirm(row, 1)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm(row, 1)}},model:{value:(row.prodSort),callback:function ($$v) {_vm.$set(row, "prodSort", $$v)},expression:"row.prodSort"}}):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"点击修改","placement":"top","enterable":false}},[_c('el-button',{staticClass:"button-new-tag unset",attrs:{"size":"small"},on:{"click":function($event){return _vm.onChangeSort(row)}}},[_vm._v(_vm._s(row.prodSort))])],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"prodId","label":"商品ID","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodName","label":"商品名称","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodHomeImg","label":"商品封面","width":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"80px","height":"80px"},attrs:{"src":row.prodHomeImg,"preview-src-list":[row.prodHomeImg]}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"prodPrice","label":"商品价格","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodRepertory","label":"商品库存","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodSellCount","label":"商品销量","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodStatus","label":"商品状态","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodType","label":"商品类型","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodPayWay","label":"支付方式","width":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.prodPayWay==1 ?"积分" :row.prodPayWay==2 ?"现金" :"积分+现金")+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"450"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(
              _vm.$store.state.powerList.indexOf('selectMall:product:list:edit')!==
                -1&&row.prodStatus!=='删除'
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$router.push('/selectMall/product/add?id=' + row.prodId)}}},[_vm._v("编辑")]):_vm._e(),(
              _vm.$store.state.powerList.indexOf(
                'selectMall:product:list:modify'
              )!==-1&&row.prodStatus=='下架'
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onModify([row], 1)}}},[_vm._v("上架")]):_vm._e(),(
              _vm.$store.state.powerList.indexOf(
                'selectMall:product:list:modify'
              )!==-1&&row.prodStatus=='上架'
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onModify([row], 2)}}},[_vm._v("下架")]):_vm._e(),(
              _vm.$store.state.powerList.indexOf(
                'selectMall:product:list:delete'
              )!==-1&&row.prodStatus!=='删除'
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onModify([row], 3)}}},[_vm._v("删除")]):_vm._e(),(
              _vm.$store.state.powerList.indexOf(
                'selectMall:product:list:modify'
              )!==-1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onChangeHot([row], row.prodIsHot==1?0 :1)}}},[_vm._v(_vm._s(row.prodIsHot==1?"设置热门" :"取消热门"))]):_vm._e(),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.creatCode(row.prodId)}}},[_vm._v("生成小程序码")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.list.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":_vm.list.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.list.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),(
        _vm.$store.state.powerList.indexOf('selectMall:product:list:delete')!==-1
      )?_c('el-button',{staticStyle:{"margin":"20px 24px 0 0"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.onModify(_vm.selection, 1)}}},[_vm._v("批量上架")]):_vm._e(),(
        _vm.$store.state.powerList.indexOf('selectMall:product:list:delete')!==-1
      )?_c('el-button',{staticStyle:{"margin":"20px 24px 0 0"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.onModify(_vm.selection, 2)}}},[_vm._v("批量下架")]):_vm._e(),(
        _vm.$store.state.powerList.indexOf('selectMall:product:list:delete')!==-1
      )?_c('el-button',{staticStyle:{"margin":"20px 24px 0 0"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.onModify(_vm.selection, 3)}}},[_vm._v("批量刪除")]):_vm._e(),(
        _vm.$store.state.powerList.indexOf('selectMall:product:list:edit')!==-1
      )?_c('el-button',{staticStyle:{"margin":"20px 24px 0 0"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeHot(_vm.selection, 0)}}},[_vm._v("批量设置热门")]):_vm._e(),(
        _vm.$store.state.powerList.indexOf('selectMall:product:list:edit')!==-1
      )?_c('el-button',{staticStyle:{"margin":"20px 24px 0 0"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeHot(_vm.selection, 1)}}},[_vm._v("批量取消热门")]):_vm._e(),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.codeVisible,"width":"30%"},on:{"update:visible":function($event){_vm.codeVisible=$event}}},[_c('div',{staticClass:"inner_top"},[_c('el-input',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入生成的二维码大小"},model:{value:(_vm.QRcodeSize),callback:function ($$v) {_vm.QRcodeSize=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"QRcodeSize"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.generateQRcode}},[_vm._v("生成")])],1),_c('div',{staticStyle:{"color":"red"}},[_vm._v("推荐宽度：280px-1280px")]),_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgUrl),expression:"imgUrl"}],attrs:{"fit":"fill","src":_vm.imgUrl}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',[_vm._v("右键点击图片，点击另存为保存图片")]),_c('el-button',{on:{"click":function($event){_vm.codeVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.codeVisible = false}}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }