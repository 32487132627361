<template>
  <div class="travelCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>商品名称:</span>
      <el-input
        style="width:400px"
        v-model="list.prodName"
        placeholder="请输入商品名称"
      ></el-input>
      <span>商品状态:</span>
      <el-select
        style="width:200px; margin:0 24px 0 10px"
        v-model.trim="list.status"
        clearable
      >
        <el-option :key="-1" label="全部" :value="undefined"></el-option>
        <el-option :key="1" label="上架" :value="1"></el-option>
        <el-option :key="2" label="下架" :value="2"></el-option>
        <el-option :key="3" label="删除" :value="3"></el-option>
      </el-select>
      <span>支付方式:</span>
      <el-select
        style="width:200px; margin:0 24px 0 10px"
        v-model.trim="list.payWay"
        clearable
      >
        <el-option :key="-1" label="全部" :value="undefined"></el-option>
        <el-option :key="3" label="积分+现金" :value="3"></el-option>
        <el-option :key="1" label="积分" :value="1"></el-option>
        <el-option :key="2" label="现金" :value="2"></el-option>
      </el-select>

      <el-button type="primary" @click="getList('reset')">查找</el-button>
      <el-button
        type="primary"
        @click="toEdit()"
        v-if="
          $store.state.powerList.indexOf('selectMall:product:list:add')!==-1
        "
      >
        添加
      </el-button>
    </div>
    <el-table
      :data="tableData"
      style="width:100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center" width="100">
      </el-table-column>
      <el-table-column
        align="center"
        prop="prodSort"
        label="排序"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-input-number
            v-if="row.prodSortVisible"
            v-model="row.prodSort"
            ref="saveTagInput"
            size="mini"
            @keyup.enter.native="handleInputConfirm(row, 1)"
            @blur="handleInputConfirm(row, 1)"
            :step="1"
            :min="0"
            :step-strictly="true"
            v-focus="{
              cls:'el-input-number',
              tag:'input',
              foc:row.prodSort,
            }"
          >
          </el-input-number>
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            content="点击修改"
            placement="top"
            :enterable="false"
          >
            <el-button
              class="button-new-tag unset"
              size="small"
              @click="onChangeSort(row)"
              >{{ row.prodSort }}</el-button
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="prodId"
        label="商品ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodName"
        label="商品名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodHomeImg"
        label="商品封面"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            style="width:80px; height:80px"
            :src="row.prodHomeImg"
            :preview-src-list="[row.prodHomeImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="prodPrice"
        label="商品价格"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodRepertory"
        label="商品库存"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodSellCount"
        label="商品销量"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodStatus"
        label="商品状态"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodType"
        label="商品类型"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodPayWay"
        label="支付方式"
        width="width"
      >
        <template slot-scope="{ row }">
          {{
            row.prodPayWay==1
            ?"积分"
              :row.prodPayWay==2
            ?"现金"
              :"积分+现金"
          }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" width="450">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="$router.push('/selectMall/product/add?id=' + row.prodId)"
            v-if="
              $store.state.powerList.indexOf('selectMall:product:list:edit')!==
                -1&&row.prodStatus!=='删除'
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onModify([row], 1)"
            v-if="
              $store.state.powerList.indexOf(
                'selectMall:product:list:modify'
              )!==-1&&row.prodStatus=='下架'
            "
            >上架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onModify([row], 2)"
            v-if="
              $store.state.powerList.indexOf(
                'selectMall:product:list:modify'
              )!==-1&&row.prodStatus=='上架'
            "
            >下架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onModify([row], 3)"
            v-if="
              $store.state.powerList.indexOf(
                'selectMall:product:list:delete'
              )!==-1&&row.prodStatus!=='删除'
            "
            >删除</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onChangeHot([row], row.prodIsHot==1?0 :1)"
            v-if="
              $store.state.powerList.indexOf(
                'selectMall:product:list:modify'
              )!==-1
            "
            >{{ row.prodIsHot==1?"设置热门" :"取消热门" }}</el-button
          >
          <el-button
            type="primary"
            size="mini"
             @click="creatCode(row.prodId)"
           
            >生成小程序码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="list.total"
    >
    </el-pagination>

    <el-button
      style="margin:20px 24px 0 0"
      type="primary"
      @click="onModify(selection, 1)"
      v-if="
        $store.state.powerList.indexOf('selectMall:product:list:delete')!==-1
      "
      >批量上架</el-button
    >
    <el-button
      style="margin:20px 24px 0 0"
      type="primary"
      @click="onModify(selection, 2)"
      v-if="
        $store.state.powerList.indexOf('selectMall:product:list:delete')!==-1
      "
      >批量下架</el-button
    >
    <el-button
      style="margin:20px 24px 0 0"
      type="primary"
      @click="onModify(selection, 3)"
      v-if="
        $store.state.powerList.indexOf('selectMall:product:list:delete')!==-1
      "
      >批量刪除</el-button
    >
    <el-button
      style="margin:20px 24px 0 0"
      type="primary"
      @click="onChangeHot(selection, 0)"
      v-if="
        $store.state.powerList.indexOf('selectMall:product:list:edit')!==-1
      "
      >批量设置热门</el-button
    >
    <el-button
      style="margin:20px 24px 0 0"
      type="primary"
      @click="onChangeHot(selection, 1)"
      v-if="
        $store.state.powerList.indexOf('selectMall:product:list:edit')!==-1
      "
      >批量取消热门</el-button
    >
        <el-dialog
          title="提示"
          :visible.sync="codeVisible"
          width="30%"
          
          >
          <div class="inner_top">
   <el-input
        style="width:300px"
        v-model.trim="QRcodeSize"
        placeholder="请输入生成的二维码大小"
        ></el-input>
        
          <el-button type="primary" @click="generateQRcode">生成</el-button>
        </div>
                <div style="color:red">推荐宽度：280px-1280px</div>
        <el-image
            
            v-show="imgUrl"
            fit="fill"
            :src="imgUrl"
          ></el-image>
  <span slot="footer" class="dialog-footer">
    <span>右键点击图片，点击另存为保存图片</span>
    <el-button @click="codeVisible = false">取 消</el-button>
    <el-button type="primary" @click="codeVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>
<script>
import {
  productList,
  productEditSort,
  prodChangeHot,
  productEdit,
  productModify,
  productDetail,
  
} from "../../api/selectMall";
import {
createEr4
} from "../../api/erCode";
export default {
  name:"selectMallProduct",
  data() {
    return {
        QRcodeSize:'',
       codeVisible:false,
       prodId:'',
         imgUrl:'',
      list:{
        status:undefined,
        payWay:undefined, // 商品类型
        currentPage:1,
        pageSize:10,
        prodName:"",
        total:0,
      },
      tableData:[],
      selection:[],
      pagination:{},
    };
  },
  created() {
    this.changeId();
  },
  mounted() {},
  directives:{
    focus:{
      inserted:function (el, option) {
        var defClass = "el-input-number",
          defTag = "input";
        var value = option.value||true;
        if (typeof value==="boolean")
          value = {
            cls:defClass,
            tag:defTag,
            foc:value,
          };
        else
          value = {
            cls:value.cls||defClass,
            tag:value.tag||defTag,
            foc:value.foc||false,
          };
        if (el.classList.contains(value.cls)&&value.foc)
          el.getElementsByTagName(value.tag)[0].focus();
      },
    },
  },
  methods:{
       creatCode(id){
      this.prodId = id
      this.codeVisible = true
      this.imgUrl = ''
    },
       async generateQRcode(){
            this.imgUrl = ''
            if(!this.QRcodeSize){
            this.$message({ message: "请输入宽度", type: "warning" }); 
            }else{
            const {data} = await createEr4({
                 prodId:this.prodId,
                widthSize:this.QRcodeSize
            })
            this.imgUrl = data.msg
            console.log(this.imgUrl);
            }
            
            },
    toNext(row) {
      this.tableData = [];
      this.$router.push("/selectMall/typeList?id=" + row.id);
    },
    changeId() {
      console.log("挂载, $route:", this.$route);
      this.getList();
    },
    async getList(type) {
      if (type=="reset") {
        this.list.pageSize = 10;
        this.list.currentPage = 1;
      }
      const { data } = await productList(this.list);
      data.list.map((item) => {
        item.prodSortVisible = false;
      });
      this.tableData = data.list;
      // this.pagination = data.pagination;
      this.list.total = data.pagination.total;
      this.list.pageSize = data.pagination.pageSize;
      this.list.currentPage = data.pagination.current;
    },
    // 批量操作
    onModify(arr, status) {
      if (!arr||arr.length < 1) {
        this.$message({ message:"请选择操作项!", type:"error" });
        return;
      }

      // 设置热门
      if (status==4) return this.setHot(arr, status);

      let ids = arr
        .map((item) => {
          return item.prodId;
        })
        .join(",");
      let msg =
        status==1?"是否上架该商品?":status==2?"是否下架该商品?":status==3
        ?"是否删除该商品?"
          :"";
      this.$confirm(msg, "提示", {
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning",
      })
        .then(async () => {
          const { data } = await productModify({
            ids:ids,
            status,
          });
          console.log(data);
          if (data.code==0) {
            this.$message({
              type:"success",
              message:data.msg,
            });
            if (
              status==3&&this.tableData.length==arr.length&&this.list.currentPage > 1
            ) {
              this.list.currentPage--;
            }
            this.getList();
          } else {
            this.$message({
              type:"error",
              message:data.msg,
            });
          }
        })
    },
    setHot(arr, status) {
      if (!arr||arr.length < 1) {
        this.$message({ message:"请选择操作项!", type:"error" });return;
      }
      let msg =
        arr[0].isHot==1
        ?"是否将该商品设置取消热门?":"是否将该商品设置热门商品?";
      this.$confirm(msg, "提示", {
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning",
      })
        .then(async () => {
          const { data } = await productEdit({
            prodId:arr[0].prodId,
            prodIsHot:arr[0].prodIsHot==1?0 :1,
          });
          console.log(data);
          if (data.code==0) {
            this.$message({
              type:"success",
              message:data.msg,
            });
            this.getList();
          } else {
            this.$message({
              type:"error",
              message:data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type:"info",
            message:"已取消操作",
          });
        });
    },
    async onChangeHot(arr, isHot) {
      if (arr.length < 1) return this.$message.error("请先选择商品!");
      let msg =
        isHot==1?"是否将该商品取消热门商品?" :"是否将该商品设置热门商品?";
      this.$confirm(msg, "提示", {
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning",
      }).then(async (res) => {
        let prodIds = arr.map((item) => {
          return item.prodId;
        });
        prodIds = prodIds.join(",");
        const { data } = await prodChangeHot({ prodIds, isHot });
        if (data.code==0) {
          this.$message({
            type:"success",
            message:data.msg,
          });
          this.getList();
        } else {
          this.$message({
            type:"error",
            message:data.msg,
          });
        }
      });
    },
    onInputInt(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)&&!reg2.test(value)) {
        return this.$message({
          type:"warning",
          message:"请输入整数!",
        });
      }
    },
    toEdit(row) { 
      let url = "/selectMall/product/add";
      if (row) url += `?id=${row.id}`;
      this.$router.push(url);
    }, 
    onChangeSort(row) {
      row.prodSortVisible = true;
      console.log("显示输入框", row);
    }, 
    async handleInputConfirm(row) {
      const { data } = await productEditSort({
        prodId:row.prodId,
        prodSort:Number(row.prodSort),
      });
      if (data.code!==0) {
        this.$message({
          message:"设置失败",
          type:"error",
        });
      } else {
        this.$message({
          message:"设置成功",
          type:"success",
        });
      }
      row.prodSortVisible = false;
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    handleSelectionChange(val) {
      this.selection = val; 
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.travelCateList {
  .el-breadcrumb {
    height:35px;
    border-bottom:1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top:50px;
    font-size:15px;
    color:#666;
    .el-input {
      width:200px;
      margin:0 40px 0 20px;
    }
  }
  .el-table {
    margin-top:30px;
    .el-table__body-wrapper {
      table {
        td {
          .cell {
            img {
              width:80px;
              height:80px;
            }
          }
        }
      }
    }
  }
  /deep/.el-dialog {
    .el-form-item__content {
      display:flex;
      .img {
        position:relative;
        height:100px;
        width:100px;
        margin:20px 20px;
        img {
          height:100px;
          width:100px;
          border:1px solid #c0ccda;
        }
        div {
          position:absolute;
          top:0;
          right:2px;
          height:10px;
          width:10px;
          z-index:100;
        }
      }
      .addImg {
        display:inline-block;
        width:100px;
        height:100px;
        border:1px dashed #c0ccda;
        text-align:center;
        line-height:100px;
        cursor:pointer;
        margin-top:20px;
        i {
          width:50px;
          height:50px;
          font-size:20px;
          color:#c0ccda;
        }
      }
    }
  }
  .el-pagination {
    margin-top:30px;
    float:right;
  }
}
</style>